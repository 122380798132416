<template>
  <Transcribe
    prop_title="title.Agreement"
    ref="transcribe"
    :prop_emit="emit_result"
    v-on:export="exportAgreement()"
    v-on:onTranscribeFinal="onTranscribeFinal()"
  >
  </Transcribe>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Transcribe from "./Transcribe.vue";
import UrbanMixin from "../common/UrbanMixin.vue";
export default {
  data: (vm) => ({
    choose_urban: null,
    emit_result: true,
  }),
  mounted() {
    this.getUrbanRenewal();
    this.setTitle(this.$i18n.t("title.Agreement"));
  },
  mixins: [UrbanMixin],
  components: {
    datetime: Datetime,
    Transcribe,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getTranscribe() {
      this.$refs.transcribe.getTranscribe();
    },
    onTranscribeFinal() {
      console.log("onTranscribeFinal in Agreement");
    },
    exportAgreement() {
      this.onExporting();
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/export_agree/`;
      let urban_name = this.$refs.transcribe.choose_urban.name;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: "blob",
        params: {
          urban: this.$refs.transcribe.choose_urban.id,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let filename = `${urban_name}_同意書.zip`;
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.onExportDone();
        });
    },
    onExporting() {
      this.$refs.transcribe.message = {
        title: this.$t("plan.agreement"),
        text: "匯出資料中...",
      };
      this.$refs.transcribe.dialog_message = true;
    },
    onExportDone() {
      this.$refs.transcribe.dialog_message = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.theme--light.v-list-item:hover::before
    opacity: 0.4
.v-list-item--link:before
    background-color: #ff9800
</style>
